/// <reference path="o365.pwa.declaration.sw.routehandlers.webResources.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { DynamicWorkboxStrategy } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.strategies.DynamicWorkboxStrategy.d.ts')>('o365.pwa.modules.sw.strategies.DynamicWorkboxStrategy.ts');
    const { AppCacheKey } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.plugins.AppCacheKey.d.ts')>('o365.pwa.modules.sw.plugins.AppCacheKey.ts');
    const { CdnCacheKey } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.plugins.CdnCacheKey.d.ts')>('o365.pwa.modules.sw.plugins.CdnCacheKey.ts');

    function initializeWebResourceRouteHandler() {
        /* -------------------- */
        /* ----- App HTML ----- */
        /* -------------------- */
        // const appHtmlStrategyOptions = ;

        self.o365.registerRoute(({ request }) => {
            return request && (request.headers.get('accept')?.includes('text/html') ?? false)
        }, new DynamicWorkboxStrategy({
            cacheName: 'O365_OFFLINE_APP_HTML',
            matchOptions: {
                ignoreSearch: true,
                ignoreVary: true
            },
            networkTimeoutSeconds: 30,
            plugins: [
                new self.workbox.cacheableResponse.CacheableResponsePlugin({
                    statuses: [0, 200],
                }),
                new AppCacheKey(),
            ],
        }), 'GET');

        /* ------------------------- */
        /* ----- App Resources ----- */
        /* ------------------------- */
        const appResources = [
            { regex: new RegExp('(?:/nt/)?api/apps/(.*).json') },
            { regex: new RegExp('(?:/nt/)?api/staticfiles/import-map/appfiles/(.*).json') },
            { regex: new RegExp('(?:/nt/)?api/usersession.(.*).json') },
            { regex: new RegExp('(?:/nt/)?api/scripts/translations.js'), matchOptions: { ignoreSearch: false } },
            { regex: new RegExp('(?:/nt/)?manifest/(.*).webmanifest') }
        ];

        for (const appResource of appResources) {
            self.o365.registerRoute(
                appResource.regex,
                new DynamicWorkboxStrategy({
                    cacheName: 'O365_OFFLINE_APP_RESOURCES',
                    matchOptions: Object.assign({}, {
                        ignoreSearch: true,
                        ignoreVary: true
                    }, appResource.matchOptions),
                    networkTimeoutSeconds: 30,
                    plugins: [
                        new self.workbox.cacheableResponse.CacheableResponsePlugin({
                            statuses: [0, 200],
                        }),
                    ],
                }),
                'GET'
            );
        }

        /* ------------------ */
        /* ----- App JS ----- */
        /* ------------------ */
        const appScripts = [
            { regex: new RegExp('(?:/nt/)?scripts/apps/(.*)') },
            { regex: new RegExp('(?:/nt/)?api/dev/scripts/vuesfcfacade/(.*)') }
        ];

        for (const appScript of appScripts) {
            self.o365.registerRoute(
                appScript.regex,
                new DynamicWorkboxStrategy({
                    cacheName: 'O365_OFFLINE_APP_SCRIPTS',
                    matchOptions: {
                        ignoreSearch: true,
                        ignoreVary: true
                    },
                    networkTimeoutSeconds: 30,
                    plugins: [
                        new self.workbox.cacheableResponse.CacheableResponsePlugin({
                            statuses: [0, 200],
                        }),
                    ],
                }),
                'GET'
            );
        }

        /* ------------------- */
        /* ----- App CSS ----- */
        /* ------------------- */
        self.o365.registerRoute(
            new RegExp('(?:/nt/)?styles/apps//(.*)'),
            new DynamicWorkboxStrategy({
                cacheName: 'O365_OFFLINE_APP_STYLES',
                matchOptions: {
                    ignoreSearch: true,
                    ignoreVary: true
                },
                networkTimeoutSeconds: 30,
                plugins: [
                    new self.workbox.cacheableResponse.CacheableResponsePlugin({
                        statuses: [0, 200],
                    }),
                ],
            }),
            'GET'
        );

        /* ---------------------------- */
        /* ----- App Translations ----- */
        /* ---------------------------- */
        self.o365.registerRoute(
            new RegExp('(?:/nt/)?api/scripts/translations/(.*)'),
            new DynamicWorkboxStrategy({
                cacheName: 'O365_OFFLINE_TRANSLATIONS',
                matchOptions: {
                    ignoreSearch: true,
                    ignoreVary: true
                },
                networkTimeoutSeconds: 30,
                plugins: [
                    new self.workbox.cacheableResponse.CacheableResponsePlugin({
                        statuses: [0, 200],
                    }),
                ],
            }),
            'GET'
        );

        /* ------------------------ */
        /* ----- Site Scripts ----- */
        /* ------------------------ */
        self.o365.registerRoute(
            new RegExp('(?:/nt/)?scripts/site/(.*)'),
            new DynamicWorkboxStrategy({
                cacheName: 'O365_OFFLINE_SITE_SCRIPTS',
                matchOptions: {
                    ignoreSearch: true,
                    ignoreVary: true
                },
                networkTimeoutSeconds: 30,
                plugins: [
                    new self.workbox.cacheableResponse.CacheableResponsePlugin({
                        statuses: [0, 200],
                    })
                ],
            }),
            'GET'
        );

        /* -------------------- */
        /* ----- Site CSS ----- */
        /* -------------------- */
        self.o365.registerRoute(
            new RegExp('(?:/nt/)?styles/site/(.*)'),
            new DynamicWorkboxStrategy({
                cacheName: 'O365_OFFLINE_SITE_STYLES',
                matchOptions: {
                    ignoreSearch: true,
                    ignoreVary: true
                },
                networkTimeoutSeconds: 30,
                plugins: [
                    new self.workbox.cacheableResponse.CacheableResponsePlugin({
                        'statuses': [0, 200],
                    }),
                ],
            }),
            'GET'
        );

        /* ------------------------- */
        /* ----- Omega 365 CDN ----- */
        /* ------------------------- */
        const cdnUrl = self.o365.cdnUrl;

        if (cdnUrl === undefined || cdnUrl === null) {
            throw new Error('cdnUrl cannot be null');
        }

        self.o365.registerRoute(
            new RegExp(`${cdnUrl}/(.*)`),
            new DynamicWorkboxStrategy({
                cacheName: 'O365_OFFLINE_CDN_RESOURCES',
                matchOptions: {
                    ignoreSearch: true,
                    ignoreVary: true
                },
                networkTimeoutSeconds: 30,
                plugins: [
                    new self.workbox.cacheableResponse.CacheableResponsePlugin({
                        statuses: [0, 200],
                    }),
                    new CdnCacheKey(),
                ],
            }),
            'GET'
        );

        self.o365.registerRoute(
            new RegExp('(?:/nt/)?cdn/(.*)'),
            new DynamicWorkboxStrategy({
                cacheName: 'O365_OFFLINE_CDN_RESOURCES',
                matchOptions: {
                    ignoreSearch: true,
                    ignoreVary: true
                },
                networkTimeoutSeconds: 30,
                plugins: [
                    new self.workbox.cacheableResponse.CacheableResponsePlugin({
                        statuses: [0, 200],
                    }),
                    new CdnCacheKey(),
                ],
            }),
            'GET'
        );

        /* ----------------------------- */
        /* ----- 3rd Party Scripts ----- */
        /* ----------------------------- */
        self.o365.registerRoute(
            ({ request }) => request.destination === 'script',
            new DynamicWorkboxStrategy({
                cacheName: 'O365_OFFLINE_THIRD_PARTY_SCRIPTS',
                matchOptions: {
                    ignoreSearch: true,
                    ignoreVary: true
                },
                networkTimeoutSeconds: 30,
                plugins: [
                    new self.workbox.cacheableResponse.CacheableResponsePlugin({
                        statuses: [0, 200],
                    }),
                ],
            }),
            'GET'
        );

        /* ---------------------------- */
        /* ----- 3rd Party Styles ----- */
        /* ---------------------------- */
        self.o365.registerRoute(
            ({ request }) => request.destination === 'style',
            new DynamicWorkboxStrategy({
                cacheName: 'O365_OFFLINE_THIRD_PARTY_STYLES',
                matchOptions: {
                    ignoreSearch: true,
                    ignoreVary: true
                },
                networkTimeoutSeconds: 30,
                plugins: [
                    new self.workbox.cacheableResponse.CacheableResponsePlugin({
                        statuses: [0, 200],
                    }),
                ],
            }),
            'GET'
        );

        /* ------------------------ */
        /* ----- Other Routes ----- */
        /* ------------------------ */
        self.o365.registerRoute(
            new RegExp('(?:/nt/)?/api/file/get-extension-whitelist'),
            new DynamicWorkboxStrategy({
                cacheName: 'O365_OFFLINE_DEFAULT_CACHE',
                matchOptions: {
                    ignoreSearch: true,
                    ignoreVary: true
                },
                networkTimeoutSeconds: 30,
                plugins: [
                    new self.workbox.cacheableResponse.CacheableResponsePlugin({
                        statuses: [0, 200],
                    })
                ],
            }),
            'GET'
        );
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.routehandlers.webResources.d.ts')>({ initializeWebResourceRouteHandler });
})();
